<template>
  <v-container fluid class="ma-0 pa-0 view-height">
    <v-row class="fill-height ">
      <v-col cols="md-8" class="image-container d-md-flex d-none">
        <v-img class="background-image view-height image" src="@/assets/login_background.png">
        </v-img>
      </v-col>
      <v-col cols="md-4" class="pa-0">
        <v-form
          ref="formLogin"
          class="fill-height"
          @submit.prevent="submitLogin($event)"
        >
          <v-container fluid class="fill-height">
            <v-row align-content="space-between" class="fill-height px-16 view-height pt-10 py-md-4">
              <v-row>
                <v-col cols="12" align="center" justify="center" class="mb-8">
                  <v-img width="195" src="@/assets/iql_logo.png" />
                </v-col>
                <v-col cols="12">
                  <custom-h2 :fontSize="29" label="Bem-vindo ao Sistema IQL" />
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium">E-mail</span>
                  <simple-text-field
                    v-model="payload.email"
                    email
                    required
                    class="mt-2"
                    placeholder="seu@email.com.br"
                  />
                </v-col>
                <v-col cols="12" class="py-0">
                  <span class="font-weight-medium">Senha</span>
                  <simple-text-field
                    v-model="payload.password"
                    password
                    required
                    class="mt-2"
                    placeholder="******"
                  />
                </v-col>
                <v-col cols="12">
                  <primary-button is-submit label="Entrar" />
                </v-col>
                <v-col cols="12">
                  <text-button
                    color="red"
                    @callAction="$router.push('reset-password')"
                    label="Esqueci minha senha"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" align="end" class="pa-0 text-sm-center mt-8">
                  @{{ new Date().getFullYear() }} | Drakkar | Todos os direitos
                  reservados
                </v-col>
              </v-row>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import PageableMixin from "@/support/utils/pageable.mixin";
import SimpleTextField from "../../../components/inputs/SimpleTextField";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import TextButton from "../../../components/buttons/TextButton";
import CustomH2 from "../../../components/texts/CustomH2";

export default {
  name: "Login",
  mixins: [PageableMixin],
  components: { CustomH2, PrimaryButton, SimpleTextField, TextButton },
  data() {
    return {
      payload: {
        email: "",
        password: "",
      },
      error: "",
    };
  },
  computed: {
    enableSubmit() {
      return !this.payload.email || !this.payload.password;
    },
  },
  methods: {
    submitLogin() {
      this.error = "";
      if (!this.$refs.formLogin.validate()) {
        return;
      }

      let loader = this.$loading.show();

      LoginService.login(this.payload)
        .then(() => {
          this.navigateTo("dashboard");
          loader.hide();
        })
        .catch(({ response }) => {
          loader.hide();
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },
  },
};
</script>

<style>
.view-height, .image {
  height: 100vh;
}

body {
  overflow: hidden;
}

.background-image {
  max-height: 100vh;
}

@media (max-width: 960px)  {
  .image {
    height: 100%;
  }  

  .image-container{
    height: 280px;
  }
}
</style>
